import Deferred from 'promise-deferred';

const Sentry = () => import(/* webpackChunkName: 'sentry' */'./sentry');
const ExternalServices = () => import(/* webpackChunkName: 'external-services' */'./external-services');

// eslint-disable-next-line consistent-return
await (async () => {
  if (typeof window === 'undefined') return;

  if (process.env.NODE_ENV === 'production') {
    const shouldDelayPageBootingDefer = new Deferred();

    // eslint-disable-next-line no-inner-declarations
    async function boot() {
      if (boot.singletonSemaphore) return;
      boot.singletonSemaphore = true;
      await Sentry();
      await ExternalServices();
      shouldDelayPageBootingDefer.resolve();
    }

    const events = ['mousemove', 'scroll', 'keydown', 'click', 'touchstart'];

    // eslint-disable-next-line no-inner-declarations
    async function onUserInteracted() {
      events.forEach((event) => document.removeEventListener(event, boot));
      if (window.localStorage) window.localStorage.setItem('should-start-immediate', '1');
      await boot();
    }

    if (window.localStorage && window.localStorage.getItem('should-start-immediate')) {
      await boot();
      return;
    }

    events.forEach((event) => document.addEventListener(event, onUserInteracted));

    setTimeout(shouldDelayPageBootingDefer.resolve, 3000);

    await shouldDelayPageBootingDefer.promise;

    await boot();
  }
})();

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/order
import '../src/boot.client'; // Very important to be first !!!

import Head from 'next/head';
import React from 'react';

import { siteTitle } from '../site-config';
import bigShouldersDisplayBlack from '../src/assets/fonts/BigShouldersDisplay-Black.woff2';
import bigShouldersDisplayMedium from '../src/assets/fonts/BigShouldersDisplay-Medium.woff2';
import poppinsBold from '../src/assets/fonts/poppins-bold-webfont.woff2';
import poppinsLight from '../src/assets/fonts/poppins-light-webfont.woff2';
import poppinsMedium from '../src/assets/fonts/poppins-medium-webfont.woff2';
import '../src/styles/global.scss';

export default function App({ Component, pageProps }) {
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.png" type="image/png" />
        <title>{siteTitle}</title>
        {/* <meta name="viewport" content="width=device-width, user-scalable=no" /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preload" href={poppinsLight} as="font" type="font/woff" crossOrigin="true" />
        <link rel="preload" href={poppinsMedium} as="font" type="font/woff" crossOrigin="true" />
        <link rel="preload" href={poppinsBold} as="font" type="font/woff" crossOrigin="true" />
        <link rel="preload" href={bigShouldersDisplayBlack} as="font" type="font/woff" crossOrigin="true" />
        <link rel="preload" href={bigShouldersDisplayMedium} as="font" type="font/woff" crossOrigin="true" />
        <style dangerouslySetInnerHTML={{
          __html: `
@font-face {
  font-family: 'Poppins';
  src: url('${poppinsLight}') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('${poppinsMedium}') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('${poppinsBold}') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
  font-family: 'Shoulders';
  src: url('${bigShouldersDisplayBlack}') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Shoulders';
  src: url('${bigShouldersDisplayMedium}') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}`,
        }}
        />
      </Head>
      <Component {...pageProps} />
    </>
  );
}
